import useTranslation from '@/utils/i18n/useTranslation';
import { Box, BoxProps, Divider, Flex, Spacer, Text, VStack } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

export type DetailDisplayItemProps = {
  label?: string;
  value?: string | number | null;
  vertical?: boolean;
  remark?: string;
  children?: ReactNode;
  suffix?: ReactNode;
} & BoxProps;

const DetailDisplayItem: FC<DetailDisplayItemProps> = (props: DetailDisplayItemProps) => {
  const { label, value, children, remark, vertical = false, suffix, ...boxPros } = props;

  const { t_errors } = useTranslation();

  if (value && children) throw new Error(t_errors('cannot-set-same-time'));

  const valueNode = children ? (
    children
  ) : (
    <VStack alignItems={vertical ? 'flex-start' : 'flex-end'}>
      <Text color='neutral.800' whiteSpace='break-spaces'>
        {value}
      </Text>
      {remark && (
        <Text fontSize='sm' color='neutral.700' whiteSpace='pre-wrap'>
          {remark}
        </Text>
      )}
    </VStack>
  );

  if (!value && typeof value !== 'number' && !children) return null;

  return (
    <Box {...boxPros}>
      <Box minH={vertical ? 'auto' : '30px'} py='1'>
        <Flex mb={vertical ? '2' : 'unset'}>
          <Text fontWeight='normal' fontSize='md' color='neutral.500'>
            {label}
          </Text>
          <Spacer></Spacer>
          {!vertical && <Flex alignItems='center'>{valueNode}</Flex>}
          {suffix}
        </Flex>
        {vertical && valueNode}
      </Box>
      <Divider />
    </Box>
  );
};

export default DetailDisplayItem;
